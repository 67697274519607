// -#-#-#-#-#- STANDARD TYPOGRAPHY -#-#-#-#-#- //
h1,.h1 {
  font-size: calc(map-get($h1, size));
  line-height: map-get($h1, line-height);
  letter-spacing: map-get($h1, letter-spacing);
  font-weight: map-get($h1, weight);
}
h2,.h2 {
  font-size: map-get($h2, size);
  line-height: map-get($h2, line-height);
  letter-spacing: map-get($h2, letter-spacing);
  font-weight: map-get($h2, weight);
}
h3,.h3 {
  font-size: map-get($h3, size);
  line-height: map-get($h3, line-height);
  letter-spacing: map-get($h3, letter-spacing);
  font-weight: map-get($h3, weight);
}
h4,.h4 {
  font-size: map-get($h4, size);
  line-height: map-get($h4, line-height);
  letter-spacing: map-get($h4, letter-spacing);
  font-weight: map-get($h4, weight);
}
h5,.h5 {
  font-size: map-get($h5, size);
  line-height: map-get($h5, line-height);
  letter-spacing: map-get($h5, letter-spacing);
  font-weight: map-get($h5, weight);
}
h6,.h6 {
  font-size: map-get($h6, size);
  line-height: map-get($h6, line-height);
  letter-spacing: map-get($h6, letter-spacing);
  font-weight: map-get($h6, weight);
}
p,.p {
  font-size: map-get($p, size);
  line-height: map-get($p, line-height);
  letter-spacing: map-get($p, letter-spacing);
  font-weight: map-get($p, weight);
}
// Multiply by $sizer-mb for smaller fontsizes on mobile
@media (max-width: $breakpoint-lg) {
  h1,.h1 {
    font-size: calc(map-get($h1, size) * $sizer-lg);
    line-height: calc(map-get($h1, line-height) * $sizer-lg);
  }
  h2,.h2 {
    font-size: calc(map-get($h2, size) * $sizer-lg);
    line-height: calc(map-get($h2, line-height) * $sizer-lg);
  }
  h3,.h3 {
    font-size: calc(map-get($h3, size) * $sizer-lg);
    line-height: calc(map-get($h3, line-height) * $sizer-lg);
  }
  h4,.h4 {
    font-size: calc(map-get($h4, size) * $sizer-lg);
    line-height: calc(map-get($h4, line-height) * $sizer-lg);
  }
  h5,.h5 {
    font-size: calc(map-get($h5, size) * $sizer-lg);
    line-height: calc(map-get($h5, line-height) * $sizer-lg);
  }
  h6,.h6 {
    font-size: calc(map-get($h6, size) * $sizer-lg);
    line-height: calc(map-get($h6, line-height) * $sizer-lg);
  }
  p,.p {
    font-size: calc(map-get($p, size) * $sizer-lg);
    line-height: calc(map-get($p, line-height));
  }
}
@media (max-width: $breakpoint-md) {
  h1,.h1 {
    font-size: calc(3rem * $sizer-md) !important;
    line-height: calc(3rem * $sizer-md) !important;
  }
  h2,.h2 {
    font-size: calc(map-get($h2, size) * $sizer-md) !important;
    line-height: calc(map-get($h2, line-height) * $sizer-md) !important;
  }
  h3,.h3 {
    font-size: calc(map-get($h3, size) * $sizer-md);
    line-height: calc(map-get($h3, line-height) * $sizer-md);
  }
  h4,.h4 {
    font-size: calc(map-get($h4, size) * $sizer-md);
    line-height: calc(map-get($h4, line-height) * $sizer-md);
  }
  h5,.h5 {
    font-size: calc(map-get($h5, size) * $sizer-md);
    line-height: calc(map-get($h5, line-height) * $sizer-md);
  }
  h6,.h6 {
    font-size: calc(map-get($h6, size) * $sizer-md);
    line-height: calc(map-get($h6, line-height) * $sizer-md);
  }
  p,.p {
    font-size: calc(map-get($p, size) * $sizer-md);
    line-height: calc(map-get($p, line-height));
  }
}

p.light {
  color: $lightgrey;
}

// -#-#-#-#-#- END STANDARD TYPOGRAPHY -#-#-#-#-#- //