section.hero {
  background-position: center center;
  background-size: cover;
  position: relative;
  height: clamp(600px, 60vh, 100vh);

  &::before {
    height: 150px;
    width: 100%;
    left: 0;
    top: 0;
    content: "";
    position: absolute;
    background-image: linear-gradient(rgba(0,0,0,.75), rgba(0,0,0,0));
  }

  ul li {
    font-size: $p-size;
  }

  .over {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .2);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  > .container {
    height: 100%;
  }
  .row {
    height: 100%;
    position: relative;
    z-index: 1;

      .hero-content {
        text-align: left !important;
    }

    * {
      color: #fff;
    }
  }
  .flex {
    max-width: 100%;
    span.h3 {
      font-family: "poppins", sans-serif !important;
      font-style: normal;
      font-size: 2.5rem !important;
      line-height: 2.5rem !important;
      font-weight: 300;
      @media (max-width: 675px) {
        font-size: 2rem !important;
        line-height: 2rem !important;
      }
      @media (max-width: 500px) {
        font-size: 1.5rem !important;
        line-height: 1.5rem !important;
      }
      opacity: 0;
      animation: fade .5s ease-in-out forwards;
      animation-delay: 2s;
      &:nth-of-type(2) { animation-delay: 2.5s; }
      &:nth-of-type(3) { animation-delay: 3s; }
      &:nth-of-type(4) { animation-delay: 3.5s; }
      &:not(:last-child) {
        margin-right: 20px;
        position: relative;

        &::before {
          content: "";
          width: 4px;
          height: 100%;
          background: $primary;
          position: absolute;
          right: -10px;
          top: 0;
        }
      }
    }
  }

  .first-content {
    position: relative;
    display: inline-block;
    margin: 0;

    .h1 {
      display: inline-block !important;
      font-style: normal !important;
      margin: 0;
      @media (max-width: 500px) {
        font-size: 3rem !important;
        line-height: 3rem !important;
      }
      span span {
        opacity: 0;
        &.first {
          animation: ease-in-out fade .5s forwards;
          animation-delay: 1s;
          font-weight: 400 !important;
        }
        &.second {
          animation: ease-in-out fade .5s forwards;
          animation-delay: 1.5s;
          text-decoration: underline;
          text-decoration-color: $primary;
        }
      }
    }

    &::before {
      position: absolute;
      left: 0;
      top: -33px;
      content: attr(data-before);
      font-size: 1.2rem;
      font-family: "Poppins", sans-serif;
      opacity: 0;
      animation: ease-in-out fade .5s forwards;
      animation-delay: .5s;
    }
  }

  .bottom-elem {
    position: absolute;
    color: #fff;
    z-index: 1;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}