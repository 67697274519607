.primary        { color: $primary !important; }
.secondary      { color: $secondary !important; }

.hidden         { opacity: 0; display: none }

.block          { display: block !important; }
.inline-block   { display: inline-block !important; }
.flex            { display: flex !important; }
.grid           { display: grid !important; }

.underline      { text-decoration: underline !important; }
.no-underline   { text-decoration: none !important; }

.position-lg-relative { @media (min-width: 992px) { position: relative; } }