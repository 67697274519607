@use "sass:map";

// -#-#-#-#-#- STANDARD VARIABLES -#-#-#-#-#- //
  // -- BREAKPOINTS -- //
  $breakpoint-sm:   576px;
  $breakpoint-md:   767px;
  $breakpoint-lg:   991px;
  $breakpoint-xl:   1200px;
  $breakpoint-xxl:  1400px;

  // -- TEXT SIZES -- //
  $h1-size:   5rem;
  $h1-height: 5rem;
  $h2-size:   2.5rem;
  $h2-height: 2.7rem;
  $h3-size:   3rem;
  $h3-height: 3rem;
  $h4-size:   2rem;
  $h4-height: 2rem;
  $h5-size:   1.5rem;
  $h5-height: 1.5rem;
  $h6-size:   1.25rem;
  $h6-height: 1.25rem;
  $p-size:    1.1rem;
  $p-height:  1.7;

  $spacer: 1.25rem;
  $spacers: (
          0: 0,
          1: $spacer * .25,
          2: $spacer * .5,
          3: $spacer,
          4: $spacer * 1.5,
          5: $spacer * 3,
          6: $spacer * 5,
  );

  $sizer-lg: .95;
  $sizer-md: .90;

  $h1: (size: $h1-size,     line-height: $h1-height,    letter-spacing: -.01562em,  weight: 800) !default;
  $h2: (size: $h2-size,     line-height: $h2-height,    letter-spacing: -.00833em,  weight: 800) !default;
  $h3: (size: $h3-size,     line-height: $h3-height,    letter-spacing: normal,     weight: 800) !default;
  $h4: (size: $h4-size,     line-height: $h4-height,    letter-spacing: .00735em,   weight: 800) !default;
  $h5: (size: $h5-size,     line-height: $h5-height,    letter-spacing: normal,     weight: 700) !default;
  $h6: (size: $h6-size,     line-height: $h6-height,    letter-spacing: .0125em,    weight: 600) !default;
  $p:  (size: $p-size,      line-height: $p-height,     letter-spacing: .0115em,    weight: 400) !default;


  // -- SHADOWS -- //
  $shadow-soft:   0 0 8px rgba(0, 0, 0, .15);
  $shadow-softer: 0 0 8px rgba(0, 0, 0, .1);

  // -- BORDER RADIUS -- //
  $border-radius:       10px;
  $border-radius-soft:  5px;
  $pill:                9999px;

  // -- COLORS -- //
  $primary:           rgb(208, 162, 70);
  $primary-darker:    mix($primary, black, 80%);
  $secondary:         rgba(255, 193, 7, 1);
  $secondary-darker:  mix($secondary, black, 80%);
  $black:             rgba(0, 0, 0, 1);
  $lightgrey:         rgb(153, 153, 153);
  $white:             rgba(255, 255, 255, 1);
  $whiteaccent:       rgb(242, 242, 242);
  $alert:             rgba(220, 53, 69, 1);
  $succes:            rgba(25, 135, 84, 1);
  $jooi-red:          #f92d1e;

// -#-#-#-#-#- END OF STANDARD VARIABLES -#-#-#-#-#-//
