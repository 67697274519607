section.text {
  p {
    font-size: 1.3rem !important;
    line-height: 1.5 !important;
    color: #333 !important;
  }
  ul {
    margin-left: 20px;

    li {
      font-size: 1.02rem !important;
      position: relative;
      color: #333 !important;
      font-family: 'Poppins', sans-serif;

      &::before {
        content: "";
        width: 8px;
        height: 8px;
        left: -20px;
        top: 9px;
        position: absolute;
        background: $primary;
        border-radius: 50%;
      }
    }
  }
}