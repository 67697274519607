form#contact_form {
  .form-wrap {
    label {
      display: none !important;
    }
    .invalid-feedback {
      text-align: left !important;
      font-size: 14px !important;
      font-family: "Poppins", sans-serif;
    }
  }
  .small {
    color: #888;
    font-size: .7rem;
  }
}
.list-xs {


  li {
    font-family: "Poppins", sans-serif;
    font-size: 1.25rem !important;

    dt {
      color: $primary !important;
      &::after {
        content: none !important;
      }
    }
    dd {
      font-size: 1rem;
      font-style: normal;
      a {
        color: #888 !important;
        font-size: 1rem;
        font-style: normal;
      }
    }
  }
}
.fa-xl {
  a {
    font-size: 1.675rem !important;
  }
}