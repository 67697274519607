section.opening-hours {
  margin: 3.75rem 0 0;

  .col-lg-9 > .flex {
    position: relative;
    opacity: 0;
    animation: fade .5s ease-in-out forwards;
    animation-delay: 3s;
    @media (max-width: $breakpoint-md) {
      flex-direction: column;
    }


    .flex {
      color: #000 !important;
      p {
        color: #000 !important;
      }
      position: relative;
      z-index: 1;
    }
  }
  p {
    margin: 0 !important;
    font-family: "Poppins", sans-serif;
  }
}

@keyframes fade {
  from  {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}