section.subhero {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 200px 0;
  position: relative;
  margin-bottom: 50px;

  h1 {
    color: #fff !important;
  }

  &::before {
    height: 150px;
    width: 100%;
    left: 0;
    top: 0;
    content: "";
    position: absolute;
    background-image: linear-gradient(rgba(0,0,0,.75), rgba(0,0,0,0));
  }

  .over {
    background: rgba(0, 0, 0, .2);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
  }

  .row {
    position: relative;
    z-index: 1;
  }

  &:not(:has(.vacancy))h1 {
    color: #fff !important;
  }

  &.vacancy {
    .image {
      width: 100%;
      height: clamp(300px, 50vh, 90vh);

      &::before {
        height: 130px;
        width: 100%;
        top: 0;
        left: 0;
        background-image: linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,0));
        position: absolute;
        content: "";
      }

      img {
        object-fit: cover !important;
        width: 100%;
        height: 100%;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        overflow: hidden;
      }

      .text-black * {
        color: #000 !important;
      }
    }

    .breadcrumbs-vc {
      margin: 15px 0 30px !important;
      font-size: 16px;
      font-family: "Poppins", sans-serif;
      @media (max-width: $breakpoint-md) {
        font-size: 13px;
      }

      a.light {
        opacity: .55;
      }
      span {
        margin: 2px 10px 0 2px;
      }
    }
    .align-w-heading {
      position: relative;
      top: 90px;

      @media (max-width: $breakpoint-lg) {
        top: 0 !important;
        padding-top: 15px;
      }
      .flex {
        align-items: center;
      }
      h6 {
        color: #272727;
        font-family: "poppins", sans-serif;
        font-size: 18px;
        @media (max-width: $breakpoint-lg) {
          font-size: 15px;
        }
      }

      i {
        margin-right: 15px;
        color: #272727;
      }
    }
  }
}