section.vacancy-detail {
  padding: 50px 0;
  .row {
    background: #f2f2f2;
    padding: 10px 0;

    * {
      color: #272727 !important;
    }
    i {
      margin-right: 15px;
    }
    h6 {
      font-size: 1.2rem !important;
    }
  }
}
section.content-vacancy {
  margin: 20px 0 50px;
  p {
    color: #333 !important;
  }
  ul {
    margin-left: 20px;

    li {
      font-size: 1.02rem !important;
      position: relative;
      color: #333 !important;
      font-family: 'Poppins', sans-serif;

      &::before {
        content: "";
        width: 8px;
        height: 8px;
        left: -20px;
        top: 9px;
        position: absolute;
        background: $primary;
        border-radius: 50%;
      }
    }
  }
}

.subhero.vacancy {
  margin-top: 130px;

  &::before {
    display: none; content: none;
  }
  .image::before {
    display: none; content: none;
  }
}