header {
  * {
    transition: all ease-in-out .2s;
  }

  nav.rd-navbar-static.rd-navbar--is-stuck.rd-navbar_transparent {
    background: #171717 !important;
  }

  .rd-navbar-inner {
    padding: 30px 0 !important;
    display: flex !important;
    align-items: center !important;
  }

  @media (min-width: calc($breakpoint-lg + 1px)) {
    .button.primary {
      border-color: $primary !important;

      &::before {
        border-color: $primary !important;
        background: $primary !important;
      }

      span {
        color: #fff !important;
      }
    }
  }


.brand-name {
  img {
    max-height: 80px;
  }
}

//on scroll
.rd-navbar--is-stuck {
  .rd-navbar-inner {
    padding: 5px 0 !important;
  }

  .brand-name {
    img {
      padding: 12px 0;
    }
  }

  //on scroll
  .rd-navbar--is-stuck {
    .rd-navbar-inner {
      padding: 5px 0 !important;
    }
    .brand-name {
      img {
        padding: 12px 0;
      }
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .rd-navbar-panel {
    border-bottom: 0 !important;
    height: 80px !important;

    button {
      margin-top: 13px;
    }

    .rd-navbar-brand {
      height: 80px !important;

      img {
        height: 60px !important;
      }
    }
  }
  .rd-navbar-nav-wrap {
    width: 350px !important;
    max-width: 100vw !important;

    .rd-navbar-nav-wrap__element {
      margin-top: 60px !important;
    }
  }
}

.rd-navbar-nav {
  font-family: 'Heebo', sans-serif !important;

  > li > a {
    font-weight: 600 !important;
  }

  a::before {
    color: $primary !important;
    top: calc(100% + 5px) !important;
  }
}

}