section.menu {
    padding: 40px 0;


    .menu-inner-price::after {
        content: none !important;
    }

    > .category > .tab {
        z-index: 1000;
        position: relative;
    }

    .category {
        position: relative;
        padding: 40px;
        max-height: 150px;
        @media (max-width: $breakpoint-xl) {
            max-height: 125px;
        }
        overflow: hidden;
        transition: all .25s ease-in-out;


        &:not(:first-of-type) {
            margin-top: 50px;
        }

        &.open {
            overflow: visible;
            max-height: none !important;
        }


        &:nth-of-type(even) {
            background: #f8f8f8;
        }

        .tab p {
            margin-top: -10px;
            margin-bottom: 30px;
            color: $primary;
        }

        .tab {
            position: relative;

            *::selection {
                background: transparent !important;
                color: inherit;
            }

            &::before {
                content: '\f078';
                position: absolute;
                font-family: "Font Awesome 6 Pro";
                right: 30px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 2rem;
                color: #333;
                transition: all .25s ease-in-out;
            }
        }

        &.open .tab::before {
            transform: translateY(-50%) rotate(180deg) !important;
        }


        &::before, &::after {
            content: "";
            width: 150px;
            height: 150px;
            background: transparent;
            position: absolute;
            pointer-events: none;
        }

        &::before {
            left: 15px;
            top: 15px;
            border-top: 4px solid $primary;
            border-left: 4px solid $primary;
        }

        &::after {
            right: 15px;
            bottom: 15px;
            border-bottom: 4px solid $primary;
            border-right: 4px solid $primary;
        }

        h3 {
            font-style: normal;
            margin-bottom: 10px;
        }

        & .category {
            max-height: none !important;
            overflow: visible !important;
            margin-top: 0;
            background: none !important;
            padding: 0;
            border-radius: 0;

            .tab {
                &::before {
                    content: none !important;
                }
            }

            &::before, &::after {
                content: none !important;
            }

            h3 {
                font-size: 2rem;
                @media (max-width: $breakpoint-xl) {
                    font-size: 1.35rem !important;
                    font-weight: 600;
                }
                font-weight: 500;
                margin-top: 30px;
            }
        }
    }

    //menu-items

    .menu-inner-wrap {
        padding: 14px 0 !important;

        .menu-inner__heading {
            font-size: 1.3rem !important;

            .menu-inner-price {
                font-size: 1.1rem;

                &::before {
                    color: $primary;
                }
            }
        }

        .menu-inner {
            margin: 0 !important;

            p {
                font-size: .8rem !important;
                color: #949494;
            }
        }
    }
}

