section.work-cta {
  position: relative;
  padding: 75px 0 150px;
  overflow: hidden;

  * {
    color: #fff !important;
  }

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0 -180px;
    @media (max-width: $breakpoint-xl) {
      object-position: 0 !important;
    }
    z-index: -1;
    transform: scale(1.1);
  }

  > .overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .row {
    position: relative;
    z-index: 1;
  }
}