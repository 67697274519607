a.button, button {
  font-family: "Poppins", sans-serif !important;
}
.button-gray-light-outline {
  color: #000;
  background-color: transparent;
  border-color: #000;
}

.button-gray-light-outline:hover,  .button-gray-light-outline:focus, .button-gray-light-outline:active,  .button-gray-light-outline:hover {
  color: #000;
  background-color: transparent;
  border-color: #000;
}

.button-gray-light-outline:hover {
  color: #fff !important;
}

.button-gray-light-outline:hover span {
  color: #fff !important;
}

.button-gray-light-outline:before {
  background-color: #000;
}

span.button-gray-light-outline {
  color: #000;
  background-color: transparent;
  border-color: #e0e0e2;
}

span.button-gray-light-outline:hover,  span.button-gray-light-outline:focus,  span.button-gray-light-outline:active,  span.button-gray-light-outline:hover {
  color: #000;
  background-color: transparent;
  border-color: #e0e0e2;
}