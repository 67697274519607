header {
  @media (min-width: calc($breakpoint-lg + 1px)) {
    .language-desktop {
      position: absolute;
      right: 0;
      transform: translateX(60px);
      top: 35px;
      display: flex;
      width: 50px;
      height: 20px;
      overflow: hidden;
      flex-direction: column;
      gap: 10px;
      cursor: pointer;
      transition: all .2s ease-out;
      &::after {
        content: '\f063';
        font-family: "Font Awesome 6 Pro";
        color: #fff;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 13px;
      }

      a {
        padding: 0;
        margin: 0;
        width: 30px;
        height: 20px;
        position: relative;

        p {
          position: absolute;
          top: 0;
          margin: 0;
          right: 0;
          transform: translateX(calc(100% + 15px));
          font-size: 14px;
          color: #fff;
        }

        &.active {
          order: -1; // sets active item fist
        }
        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
          position: relative;
          top: -14px;
        }
      }

      &:hover {
        height: auto;
        overflow: visible;
      }
    }
    nav.rd-navbar--is-stuck {
      .language {
        top: 30px;
      }
    }
  }

  .language-mobile {
    display: none;
  }

  @media (max-width: $breakpoint-lg) {
    .language-desktop {
      display: none;
    }
    .language-mobile {
      display: block;
      position: absolute;
      right: 30px;
      top: 20px;

      a {
        color: #fff;
        cursor: pointer;
        text-align: right;
        width: 100%;
        font-size: .8rem;
        &::selection {
          background: transparent;
          color: inherit;
        }
      }
      .language-menu-mobile.open {
        display: flex !important;
      }
      .language-menu-mobile {
        background: #f2f2f2;
        padding: 15px;
        display: none;
        flex-direction: column;
        a {
          display: flex;
          gap: 5px;
          align-items: center;

          img {
            width: 30px;
            height: 20px;
          }
          p {
            margin: 0;
            padding: 0;
            color: #333;
          }
        }
      }
    }
  }
}