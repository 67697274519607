footer {
  li {
    dd {
      a {
        color: inherit !important;
        text-decoration: underline;
      }
    }
  }
  .list-bordered {
    @media (max-width: 974px) {
      li:last-child {
        position: relative;
        bottom: -12px;
        font-size: 80%;
      }
    }
    @media (max-width: 520px) {
      li {
        display: block;
      }
    }
  }
  ul.list-bordered {
    li:last-of-type {
      dt::after {
        content: none;
      }
    }
  }
  .list-nav li, .list-bordered {
    font-family: "Poppins", sans-serif;
  }
  .list-bordered {
    li {
      font-size: .9rem;
    }
  }
  .rights {
    font-size: 14px !important;
  }

  @media (max-width: $breakpoint-lg) {
    .fa-xl a {
      color: $primary;
    }
  }
}