::selection {
  background: $primary;
  color: #fff;
}

::-moz-selection {
  background: $primary;
  color: #fff;
}

html::-webkit-scrollbar {
  width: 10px;
}

html::-webkit-scrollbar-track {
  background-color: #fff;
}

html::-webkit-scrollbar-thumb {
  background: $primary;
}

ul {
  padding: 0;
}

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  color: #333;
}

img {
  max-width: 100%;
  object-fit: cover;
}

.header_fix {
  position: absolute;
  top: 0;
  left: 0;
  height: 130px;
  width: 100%;
  background: #000;
  z-index: -1;
}

hr {
  color: $primary !important;
  opacity: .7 !important;
}

rw-app-view {
  z-index: 99 !important;
}

.reserve #resengo-process-flow {
  max-width: none !important;
}

rw-app-view {
  --primary-color: #D0A246 !important;
}

.weatherwidget-io, .weatherwidget-io * {
  pointer-events: none !important;
}