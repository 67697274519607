@media (min-width: calc($breakpoint-xl + 1px)) {
  .col-md-6.corners {
    position: relative;

    &::before {
      content: "";
      width: 100px;
      height: 100px;
      position: absolute;
      z-index: 0;
    }

    &::before {
      left: 5px;
      @media (max-width: 1599px) {
        left: -8px;
      }
      top: -20px;
      border-left: 4px solid $primary;
      border-top: 4px solid $primary;
    }

    &::after {
      right: 10px;
      @media (max-width: 1599px) {
        right: -3px;
      }
      bottom: -15px;
      border-right: 4px solid $primary;
      border-bottom: 4px solid $primary;
    }
  }
}
