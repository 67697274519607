section.parallax {
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  @media (max-width: 991px) {
    background-attachment: initial;
  }
  position: relative;
  padding: 150px 0;

  .overlay {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.4);
    @media (max-width: $breakpoint-lg) {
      background: rgba(0,0,0,0);
    }
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .row  {
    position: relative;
    z-index: 1;

    * {
      color: #fff;
    }
  }
}
